.submenu-item-name, .ant-menu-name ,.submenu-item-custom {
    color: #fff;
    font-size: 14px;
    font-weight: 500 !important;
    width: 230px !important;
    margin-left: auto !important;
    border-radius: 9px 1px 1px 9px !important;
}
.ant-menu:not(.ant-menu-horizontal){
    background-color: #1f78b4 !important;
    border-top-left-radius: 19px !important; 
    border-bottom-left-radius: 19px !important; 
}

.ant-menu-item-selected a > .submenu-item-name{
    color: black !important;

}

.ant-menu-submenu-title{
    color: white;

}

.ant-menu-item-selected {
    background-color: #f8f6f6 !important;
    color: black !important;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
    content: none !important;
}

/* div[title="Service recommendation"] {
    padding-left: 30px !important;
    font-size: 14px !important;
} */