#ant-collapse-header-white {
    color: #fff !important;
}

#ant-collapse-header-black {
    color: black !important;
}

.ant-panel {
    background-color: #007bff !important;
    border-color: #007bff !important;
    color: white !important;
    font-size: 1.875rem !important;
    border-radius: 0.2rem !important;
    margin: 10px 0
}

.ant-panel-warning {
    background-color: #ffc107 !important; 
    border-color: #ffc107 !important;
    color: white !important;
    font-size: 1.875rem !important;
    border-radius: 0.2rem !important;
    margin: 10px 0 !important
}