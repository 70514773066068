@import '~antd/dist/antd.css';

@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import './assets/scss2/custom.scss';

@import './assets/scss2/style.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  /*background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.ant-menu:not(.ant-menu-horizontal) {
  background: #00003f;
}

.ant-menu
  .ant-menu-light
  .ant-menu-root
  .ant-menu-horizontal
  .ant-menu-submenu {
  background: #fff !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item .anticon {
  color: #fff;
}
.submenu-item-name {
  color: #fff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}
.user-top-menu {
  height: 40px;
  width: 40px;
  background-color: #07a7d3;
  border-radius: 50%;
  display: inline-block;
}

.notification-top-menu {
  height: 40px;
  width: 40px;
  background-color: #07a7d3;
  border-radius: 50%;
  display: inline;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

input {
  border-top: none;
  border-left: none;
  border-right: none;
  outline: 0;
  width: 300px;
}

input:focus {
  outline: none !important;
}

.provider-select {
  all: unset;
  padding-right: 15px;
  border-bottom: 1px solid;
  background: url('https://www.charbase.com/images/glyph/9662') no-repeat right;
  background-size: 16px;
  width: 75%;
  border-color: #b2beb5;
}

.btn-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
#inputRegion::placeholder {
  color: #fff;
}
#inputConnector::placeholder {
  color: #fff;
}
#rdsForm input,
#ec2Form input {
  width: 273px !important;
}


.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #28bae6;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.custom-ui > h2 {
  margin-top: 0;
  background: none;
  color: #fff;
}

.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

.outer-element{
  width:'75%'; 
  padding:'5px';
  background-color: '#3386ff'
}

.inner-element{
  width:'100%';
  background-color: '#3386ff';
  color: 'white';
  padding : '5px';
  border-color:'white'
}

.select-dash {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Some browsers will not display the caret when using calc, so we put the fallback first */ 
  background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat 98.5% !important; /* !important used for overriding all other customisations */
  background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat calc(100% - 10px) !important; /* Better placement regardless of input width */
  font-size: 16px;
}
/*For IE*/
.select-dash::-ms-expand { display: none; }

