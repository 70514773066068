/* .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar::before{
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    background-color: #ffffff;
    top: -5px;
    right: -16px;
    border-bottom-right-radius: 10px;

}
.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar::after{
    content: '';
    position: absolute;
    height: 11px;
    width: 16px;
    background-color: #ffffff;
    bottom: 0px;
    right: -16px;
    border-top-right-radius: 10px;

} */
.SA-wrapper {
 .ant-tabs-nav-container {
  overflow: visible;
 }
 .ant-tabs-nav-wrap {
  overflow: visible;
 }
 .ant-tabs-nav-scroll {
  overflow: visible;
 }
 .ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
 .ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
  text-align: left !important;
 }
 .ant-tabs-nav .ant-tabs-tab-active {
  color: black;
  background-color: #ffffff;
 }
 .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
  right: -10px;
  width: 10px;
  background-color: #ffffff;
 }
}
.SATopChat {
 padding: 5px 16px;
 background-color: #dae7e8;
 display: inline-block;
 border-radius: 16px;
 transform: translateY(-16px);
 .SAChatIcon {
  height: auto;
  width: 16px;
  margin: auto 0;
  padding-bottom: 3px;
  margin-right: 16px;
 }
 .SAChatPara {
  font-size: 16px;
  color: #070707;
 }
}

.SATopBoxHeader {
 font-size: 16px;
 color: #070707;
 font-weight: 700;
 vertical-align: text-bottom;
 line-height: 1.2;
 margin-bottom: 10px;
}
.SATopBox {
 background-color: #d9e7e8;
 padding: 1.5rem 0;
 padding-left: 1.5rem;
 border-radius: 1.25rem;
 margin-top: 5px;
}
.SATopBoxHeading {
 font-size: 16px;
 font-weight: 700;
 line-height: 1.8;
}
.SATopBoxHeading1 {
 color: #538db1;
}
.SATopBoxHeading2 {
 color: #d11915;
}
.SATableTab {
 position: relative;
 height: 100%;
 width: 100%;
}
.SATableContainer {
 background-color: #ffffff;
 padding-top: 20px;
 padding-left: 30px;
 padding-right: 20px;
 padding-bottom: 10px;
 border-radius: 16px;
 margin: 5px;
 margin-top: -20px;
 -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75);
 -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75);
 box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
 z-index: 10;
}
.table-striped tbody tr:nth-of-type(odd) {
 background-color: #d9e7e8 !important;
}
table {
 background-color: #ffffff;
}
.SA-wrapper {
 .ant-tabs .ant-tabs-left-content,
 .ant-tabs .ant-tabs-right-content {
  overflow: visible !important;
  margin-left: 115px !important;
 }
}

.SATableHeading {
 color: #538db1;
}
.SATableDataCheck {
 color: #4b4b4b;
 font-weight: bold;
}

.SATableDataStatus {
 font-weight: bold;
 text-align: center;
}
.SATableData {
 color: #538db1;
 text-align: center;
 font-weight: bold;
}
